import React from 'react'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import useExpertsQuery from '../hooks/useExpertsQuery'
import OurExpertsSection from './OurExpertsSection'

const {
    AFRICA,
    NORTH_AMERICA,
    AUSTRALIA_AND_NEW_ZEALAND,
    ASIA,
    EUROPE,
    LATIN_AMERICA,
} = constants

const randomizeArr = (arr) =>
    [...arr].sort(() => Math.random() - Math.random()).slice(0, 8)

const getExpertsForContinent = (experts, code) =>
    experts.filter(({ content: { continentCodes } }) =>
        continentCodes.includes(code)
    )

const getRandomizedExpertsForContinent = (experts, code) =>
    randomizeArr(getExpertsForContinent(experts, code))

const getUniqueExpert = (finalList, experts, attempts = 0) => {
    const expert = experts[Math.floor(Math.random() * experts.length)]
    const expertAlreadyAdded =
        !!expert &&
        typeof finalList.find(({ id }) => expert.id === id) !== 'undefined'
    /**
     * Try 3 times to find a unique expert that is not already added.
     * If we can't find one after 3 tries there probably is no unique expert available
     */
    if (expertAlreadyAdded && attempts !== 3) {
        return getUniqueExpert(finalList, experts, attempts + 1)
    }
    return expert
}

const OurExpertsSectionWithState = ({ content }) => {
    const {
        isFetching,
        experts = [],
        error,
    } = useExpertsQuery({
        continentCode: content.continentCode,
    })
    let parsedExperts = []
    if (!isFetching && !error) {
        const showAllContinents = content.continentCode === ''
        if (showAllContinents) {
            /**
             * These are put in the correct order the svg renders the circles
             * That is why Africa is twice in the list because there are 2 circles in Africa
             */
            const continents = [
                getExpertsForContinent(experts, LATIN_AMERICA),
                getExpertsForContinent(experts, AFRICA),
                getExpertsForContinent(experts, NORTH_AMERICA),
                getExpertsForContinent(experts, ASIA),
                getExpertsForContinent(experts, AUSTRALIA_AND_NEW_ZEALAND),
                getExpertsForContinent(experts, AFRICA),
                getExpertsForContinent(experts, EUROPE),
            ]
            parsedExperts = continents.reduce(
                (expertsAcc, continentExperts) => {
                    const randomExpert = getUniqueExpert(
                        expertsAcc,
                        continentExperts
                    )

                    return [...expertsAcc, randomExpert]
                },
                []
            )
        } else {
            parsedExperts = getRandomizedExpertsForContinent(
                experts,
                content.continentCode
            ).slice(0, 15)
        }
    }
    const filteredExperts = parsedExperts.filter((expert) => {
        if (!expert) {
            return null
        }
        const {
            content: { profileImage = {} },
        } = expert
        const imageUrl = profileImage.filename || null
        if (imageUrl === null) {
            return null
        }
        return expert
    })
    return (
        <SbEditable content={content}>
            <OurExpertsSection
                {...content}
                experts={filteredExperts}
                isFetching={isFetching}
            />
        </SbEditable>
    )
}

export default OurExpertsSectionWithState

import React, { useRef, useLayoutEffect } from 'react'

const BannerScript = ({ script, className }) => {
    const ref = useRef()

    useLayoutEffect(() => {
        const range = document.createRange()
        range.selectNode(ref.current)
        const documentFragment = range.createContextualFragment(script)

        // Inject the markup, triggering a re-run!
        ref.current.innerHTML = ''
        ref.current.append(documentFragment)
    }, [])

    return <div ref={ref} className={className} />
}

export default BannerScript
